import React from 'react';
import { GoogleMap, useJsApiLoader, Marker, InfoWindow } from '@react-google-maps/api';
import constants from './AppConstant';

const containerStyle = {
  width: '100%',
  height: '400px'
};

const center = {
  lat: 19.164032,
  lng: 72.847743
};




function SimpleMap(props) {

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyBEuONfMtWR26-C7NyKR3Xab_X8STRsp9I"
  })

  const [map, setMap] = React.useState(true)

  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds);
    setMap(map)
  }, [])

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])

  const [marker, setMarker] = React.useState(null)

  //console.log(map)

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={{
        lat: 19.164032,
        lng: 72.847743
      }}
      zoom={18}
      onLoad={onLoad}
      onUnmount={onUnmount}
    >
      <Marker
        position={{
          lat: 19.164032,
          lng: 72.847743
        }}
        title="ABP SECURITY AND FACILITY SERVICES"
        onClick={() => {
          setMarker(center);
        }}

      />
      {marker ? (
        <InfoWindow
          onCloseClick={() => {
            setMarker(null);
          }}

          position={{
            lat: 19.164032,
            lng: 72.847743
          }}
        >
          <div className='info-window'>
            <h5>ABP SECURITY AND FACILITY SERVICES</h5>
            <div className="info-content">
              <p style={{ marginBottom: '5px' }}><b>Address:</b> {constants.ADDRESS}</p>
              <p style={{ marginBottom: '5px' }}><b>Tel:</b> 9768158592 / 8369795270</p>
              <p style={{ marginBottom: '5px' }}><b>Email:</b> &#097;&#098;&#112;&#115;&#099;&#101;&#114;&#116;&#064;&#103;&#109;&#097;&#105;&#108;&#046;&#099;&#111;&#109;</p>
            </div >
          </div>
        </InfoWindow>
      ) : null}

      <></>
    </GoogleMap>
  ) : <></>;
}

export default SimpleMap

