import React from 'react'
import { Link } from "react-router-dom";
import Banner from './Banner'
import sgImg from "../../assets/images/sg.png"
import Guard from './Guard'
import { Helmet } from "react-helmet-async";
import arrowBlack from '../../assets/images/arrow-black.png'


export const Home = () => {
    return (
        <>
         <Helmet>
          <title>Best Security Guard Agency in Mumbai | Home, Bank, Corporate Security Service in Mumbai</title>
          <meta name="description" content="ABP Security & Facility Services is a leading security guard agency in Mumbai. We provide end-to-end security solutions to home, residential, personal, corporate & banks in Mumbai, Navi Mumbai & Thane. Contact us now!" />
        </Helmet>

            <Banner></Banner>

            {/* Welcome section */}
            <section className="welcome_section layout_padding">
                <div className="container">
                    <div className="row">
                        <div className=" col-md-6">
                            <div className="welcome_detail">
                                <h3>
                                    Welcome
                                </h3>
                                <h2>
                                    Welcome to ABP security services
                                </h2>
                                <p>
                                    We have great pleasure to introduce <strong>ABP SECURITY AND FACILITY SERVICES</strong>
                                    You would definitely agree with us that the concept of security and allied services now a day is not merely a status symbol, but the significance of the vital role it plays either to protect tine valuables or to maintain the Confidentiality of the business security of an organization of repute needs no further elaboration abp security and facility services is one of the foremost professionally and managed organization operating in the
                                    State of Maharashtra.</p>
                                <p>with <strong>15 YEARS OF EXPERIENCE</strong>, we are rendering the services to Corporate office, Plants, Factories / Multiplexes, Residential, Complexes, Construction Sites and also providing Specialized security to Banking the company is</p>
                                <div><span className=''><i className="far fa-clock pagelayer-icon-circle pagelayer-animation" aria-hidden="true"></i> 24/7 Opened</span> &nbsp;
                                    <span><i className="fas fa-trophy pagelayer-icon-circle pagelayer-animation" aria-hidden="true"></i> Best Security</span></div>
                                <div className="welcome_detail-btn">
                                    <Link to="/about-us">
                                        <span>
                                            Read More
                                        </span>
                                        <img src={arrowBlack} alt="" className="ml-2" />
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className=" col-md-6">
                            <div>
                                <img className="img-fluid" src={sgImg} alt="" />
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            {/*Sercie section*/}
            <section className="service_section ">
                <div className="container">
                    <div className='row'>
                        <div className="col-md-12">
                            <div className="service_detail">
                                <h3>
                                    We
                                </h3>
                                <h2>
                                    we provide service
                                </h2>
                            </div>
                            <div className="service_img-container">
                                <div className="service_img-box i-box-1">
                                    <Link to="/services"> Bank security</Link>
                                </div>
                                <div className="service_img-box i-box-2">
                                    <Link to="/services">society Guard </Link>
                                </div>
                                <div className="service_img-box i-box-3">
                                    <Link to="/services">CORPORATE OFFICE Security </Link>
                                </div>
                                <div className="service_img-box i-box-4">
                                    <Link to="/services"> Home security</Link>
                                </div>
                                <div className="service_img-box i-box-5">
                                    <Link to="/services">Factory Security </Link>
                                </div>
                                <div className="service_img-box i-box-6">
                                    <Link to="/services"> PRIVATE security</Link>
                                </div>
                                <div className="service_img-box i-box-7">
                                    <Link to="/services">House Keeping </Link>
                                </div>
                                <div className="service_img-box i-box-8">
                                    <Link to="/services"> Pest Control</Link>
                                </div>
                            </div>
                            <div className="service_btn">
                                <Link to="/services">
                                    <span>
                                        Read More
                                    </span>
                                    <img src={arrowBlack} alt="" className="ml-2" />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/** Body gaurd */}
            <Guard></Guard>

            <section className='home-brochure'>
                <div className="container mb-4">
                    <div className="card" >
                        <div className="card-body text-center" style={{ backgroundColor: '#673ab7', color: '#fff' }}>
                            <h3 className="card-title">Check Our Brochure</h3>
                            <p className="card-text">To know more about us and our offered services click on below link and see our detailed Brochure.</p>
                            <div className='text-center'><Link to="brochure" className="button btn btn-primary btn-lg"> <span>Brochure</span></Link></div>
                        </div>
                    </div>
                </div>

            </section>
        </>
    )
}
