import React from 'react'
import Slider from "react-slick";
import banner1 from "../../assets/images/security-1.jpg"
import banner2 from "../../assets/images/security-2.jpg"
import banner3 from "../../assets/images/security-3.jpg"
import banner4 from "../../assets/images/security-4.jpg"
import banner5 from "../../assets/images/security-5.jpg"
import banner6 from "../../assets/images/security-6.jpg"
import banner7 from "../../assets/images/security-7.jpg"
import banner8 from "../../assets/images/security-8.jpg"
import banner9 from "../../assets/images/security-9.jpg"

const Guard = () => {

  var settings = {
    dots: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 3,
    speed: 500,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }]
  };

  const bannerData = [
  {img: banner1},
  {img: banner2},
  {img: banner3},
  {img: banner4},
  {img: banner5},
  {img: banner6},
  {img: banner7},
  {img: banner8},
  {img: banner9},
];

  return (

    <section className="security_section layout_padding">
      <h3>
        Security
      </h3>
      <h2>
        Our Guard
      </h2>
      <div className="security_container">
        <div className="carousel-wrap">
          <div className="owl-carousel">
            <Slider  {...settings}>

              {
                bannerData.map((b, i) => {
                  return <div className=" item" key={i}>
                    <div className={`security_img-box i-box-${i + 1}`} style={{ backgroundImage: `url(${b.img})`, border: "1px solid #c2c2c2" }}>
                     
                    </div>
                  </div>
                })
              }
            </Slider>
          </div>
        </div>
      </div>

    </section>
  )
}

export default Guard