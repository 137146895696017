import React from 'react'
import { Link } from "react-router-dom"; 
import fb from '../assets/images/fb.png'
import twitter from '../assets/images/twitter.png'
import linkedin from '../assets/images/linkedin.png'
import youtube from '../assets/images/youtube.png'
import location from '../assets/images/location.png'
import phone from '../assets/images/phone.png'
import mail from '../assets/images/mail.png'
import constants from './AppConstant';

const Footer = () => {

    const getYear = () => {
        return new Date().getFullYear();
    }

    return (
        <>
            <section className="info_section layout_padding">
                <div className="container">
                    <div className="row">
                        <div className=" col-md-4 info_detail">
                            <div>
                                <p>
                                The ABP SECURITY AND FACILITY SERVICES pride themselves on the quality and professionalism of their staff as they are the window of the business what they do. how they do it, when in or out of uniform services...
                                <Link className='link mx-2' to="/about-us">read more</Link>
                                </p>
                            </div>
                        </div>
                        <div className=" col-md-4 address_container">
                            <div>
                                <h3>
                                    Address:
                                </h3>
                                <div className="address_link-container">
                                    <Link to="#">
                                        <img src={location} alt="" />
                                            <span> {constants.ADDRESS}
                                            </span>
                                    </Link>
                                    <Link to="#" onClick={(e) => { window.open('tel:+919768158592'); }}>
                                        <img src={phone} alt="" />
                                            <span> Tel: 9768158592 / 8369795270
                                            </span>
                                    </Link>
                                    <Link to="#" onClick={(e) => { window.location = "mailto:abpscert@gmail.com"; }}>
                                        <img src={mail} alt="" />
                                            <span>
                                                Email: &#097;&#098;&#112;&#115;&#099;&#101;&#114;&#116;&#064;&#103;&#109;&#097;&#105;&#108;&#046;&#099;&#111;&#109;
                                            </span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className=" col-md-4 news_container">
                            <div>
                            
                                <div>
                                    <ul className='nav navbar-nav'>
                                        <li ><Link className='nav-link text-white' to="about-us">About Us</Link></li>
                                        <li><Link className='nav-link text-white' to="services">Services</Link></li>
                                        <li><Link className='nav-link text-white' to="gallery">Gallery</Link></li>
                                        <li><Link className='nav-link text-white' to="contact-us">Contact us</Link></li>
                                        <li><Link className='nav-link text-white' to="brochure">Brochure</Link></li>
                                    </ul>  
                                </div>
                                <div className="social_container">
                                    <div>
                                        <img src={fb} alt=""/>
                                    </div>
                                    <div>
                                        <img src={twitter} alt="" />
                                    </div>
                                    <div>
                                        <img src={linkedin} alt=""/>
                                    </div>
                                    <div>
                                        <img src={youtube} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="container-fluid footer_section">
                <p className='small'>
                    Copyright &copy; {  getYear() } All Rights Reserved By
                    ABP Security & Facility Services
                </p>
            </section>
        </>

    )
}

export default Footer