import React from 'react'
import { Helmet } from 'react-helmet-async';

const Services = () => {
  return (
    <>
      <Helmet prioritizeSeoTags>
        <title>Home, Residential, Personal, Bank, Corporate, Hotel Security Services in Mumbai</title>
        <meta name="description" content="ABP Security and Facility Services provide the best security services in Mumbai, Navi Mumbai & Thane. Contact us now for home, residential, bank, personal, corporate, hotel or any event security services." />
      </Helmet>
      <section>
        <div className="container">

          <div className="other-pg-heading">
            <h2 className='h1-responsive'>Our Services</h2>
          </div>

          <section className="mb-4">
          <div className="accordion" id="accordionExample">
              <div className="col-md-12">
                {/* Security serice card */}
                <div className="card">
                  <div className="card-header text-white h5" id="headingOne" style={{ backgroundColor: '#2c308c'}} data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                    
                    Security Services
                  </div>
                  <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                  <div className="card-body">

                    <p>ABP Security &amp; Facility Services currently provides the following sercurity services :</p>

                    <ul className="list-group list-group-flush">
                      <li className="list-group-item">Corporate & Commercial Security</li>
                      <li className="list-group-item">Industrial Security</li>
                      <li className="list-group-item">Executive Protection</li>
                      <li className="list-group-item">Hotel Security & Hospital Security</li>
                      <li className="list-group-item">Special School Security</li>
                      <li className="list-group-item">Bank Security</li>
                      <li className="list-group-item">Canine Squad</li>
                      <li className="list-group-item">Event Security</li>
                      <li className="list-group-item">Facility Management</li>
                      <li className="list-group-item">Parking Management</li>
                      <li className="list-group-item">Electronic Security Systems</li>
                      <li className="list-group-item">Emergency Response Team</li>
                      <li className="list-group-item">Security Training</li>
                    </ul>
                  </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                {/* pest controll  card */}
                <div className="card">
                  <div className="card-header text-white h5" id="headingTwo" style={{ backgroundColor: '#2c308c'}} data-toggle="collapse" data-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
                      Pest Control Services
                  </div>
                  <div id="collapseTwo" className="collapse show" aria-labelledby="headingTwo" data-parent="#accordionExample">
                    <div className="card-body">

                      <p>ABP Security &amp; Facility Services provides the <span style={{ fontWeight: '500' }}>Pest Control</span> services for the homes, commercial institutions, offices, outdoor etc.
                        Are you looking for <span style={{ fontWeight: '500' }}>Pest Control</span> services ? If yes then your search ends here. We provides all kind of pest control services. Here are the some service list</p>
                      <ul className="list-group list-group-flush">
                        <li className="list-group-item">Commercial pest control</li>
                        <li className="list-group-item">Residential pest control</li>
                        <li className="list-group-item">Hotel and Hospital pest control</li>
                        <li className="list-group-item">Pest control service for pre construction buildings</li>
                        <li className="list-group-item">Pest control service for bed bugs</li>
                        <li className="list-group-item">Pest control service for spider, mosquito, fleas</li>
                        <li className="list-group-item">Pest control service for cockroach</li>
                        <li className="list-group-item">Pest control service for lizards</li>
                        <li className="list-group-item">Pest control service for beetle</li>
                        <li className="list-group-item">Pest control service for termite</li>
                        <li className="list-group-item">Pest control service for rats, mice</li>

                      </ul>

                    </div>
                  </div>
                </div>
              </div>
            
              <div className="col-lg-12">
                {/* pest controll  card */}
                <div className="card">
                  <div className="card-header text-white h5" id="headingThree" style={{ backgroundColor: '#2c308c'}} data-toggle="collapse" data-target="#collapseThree" aria-expanded="true" aria-controls="collapseThree">
                  Housekeeping Services
                  </div>

                  <div id="collapseThree" className="collapse show" aria-labelledby="headingThree" data-parent="#accordionExample">
                    <div className="card-body">
                      <p className='card-text'>ABP Security &amp; Facility Services provides the <span style={{ fontWeight: '500' }}>housekeeping</span> services for the homes such as house maid, baby care, patient care, cook etc. Also office cleaning services.</p>
                      <p className='card-text'>We follow the 6's of housekeeping(sort, set in order, shine, standardize, sustain and safety)</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

      </section>
    </>
  )
}

export default Services