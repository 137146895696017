import React from 'react'
import { Helmet } from "react-helmet-async";

const Servicepages = (props) => {
    // Use the API response data passed as props.data
    const { data } = props;
    return (
        <>
            <Helmet>
                <title>{data.meta_title}</title>

                <meta name="description" content={data.meta_description} />
            </Helmet>
            <section className="welcome_section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="other-pg-heading">
                                <h2 className='h1-responsive'>{data.title}</h2>
                            </div>
                            <div>&nbsp;</div>
                            <div dangerouslySetInnerHTML={{ __html: data.content }} />
                            <div>&nbsp;</div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Servicepages