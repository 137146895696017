/* global $ */
import React, {useEffect} from 'react'
import '../assets/css/gallery.css'
import img1 from "../assets/images/gallery/1.jpg"
import img2 from "../assets/images/gallery/2.jpg"
import img3 from "../assets/images/gallery/3.jpg"
import img4 from "../assets/images/gallery/4.jpg"
import img5 from "../assets/images/gallery/5.jpg"
import img6 from "../assets/images/gallery/6.jpg"
import img7 from "../assets/images/gallery/7.jpg"
import img8 from "../assets/images/gallery/8.jpg"
import img9 from "../assets/images/gallery/9.jpg"
import img10 from "../assets/images/gallery/10.jpg"
import img11 from "../assets/images/gallery/11.jpg"
import img12 from "../assets/images/gallery/12.jpg"
import img13 from "../assets/images/gallery/13.jpg"
import img14 from "../assets/images/gallery/14.jpg"
import img15 from "../assets/images/gallery/15.jpg"
import img16 from "../assets/images/gallery/16.jpg"
import img17 from "../assets/images/gallery/17.jpg"
import img18 from "../assets/images/gallery/18.jpg"
import img19 from "../assets/images/gallery/19.jpg"
import img20 from "../assets/images/gallery/20.jpg"
import img21 from "../assets/images/gallery/21.jpg"
import img22 from "../assets/images/gallery/22.jpg"
import img23 from "../assets/images/gallery/23.jpg"
import img24 from "../assets/images/gallery/24.jpg"
import img25 from "../assets/images/gallery/25.jpg"
import img26 from "../assets/images/gallery/26.jpg"
import img27 from "../assets/images/gallery/27.jpg"
import img28 from "../assets/images/gallery/28.jpg"
import img29 from "../assets/images/gallery/29.jpg"
import img30 from "../assets/images/gallery/30.jpg"
import img31 from "../assets/images/gallery/31.jpg"
import img32 from "../assets/images/gallery/32.jpg"
import img33 from "../assets/images/gallery/33.jpg"
import img34 from "../assets/images/gallery/34.jpg"

import { Helmet } from 'react-helmet-async';

export const Gallery = () => {

  const gallery = [
    { img: img29 },
    { img: img34 },
    { img: img1 },
    { img: img2 },
    { img: img3 },
    { img: img4 },
    { img: img5 },
    { img: img6 },
    { img: img7 },
    { img: img8 },
    { img: img9 },
    { img: img10 },
    { img: img11 },
    { img: img12 },
    { img: img13 },
    { img: img14 },
    { img: img15 },
    { img: img16 },
    { img: img17 },
    { img: img18 },
    { img: img19 },
    { img: img20 },
    { img: img21 },
    { img: img22 },
    { img: img23 },
    { img: img24 },
    { img: img25 },
    { img: img26 },
    { img: img27 },
    { img: img28 },
    
    { img: img30 },
    { img: img31 },
    { img: img32 },
    { img: img33 },
    
  ];

  useEffect(() => {
    $(function () {
      
      $(".fancybox").fancybox({
        type        : 'image',
        openEffect: "none",
        closeEffect: "none"
      });

    $(".zoom").hover(function () {

      $(this).addClass('transition');
      }, function () {

      $(this).removeClass('transition');
    });     
    });    
  })
  


  

  return (
    <>
      <Helmet prioritizeSeoTags>
        <title>Gallery- ABP Security &amp; Facility Services</title>
        <meta name="description" content="Check our guards and teams photo gallery" />
      </Helmet>
      <section className="welcome_section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="other-pg-heading">
                <h2 className='h1-responsive'>Gallery</h2>
              </div>

              {/* Start Gallery */}
              <div className="row">

                {
                  gallery.map((b, i) => {
                    return <div key={i} className="col-lg-3 col-md-4 col-xs-6 thumb">
                      <a href={b.img} className="fancybox" rel="ligthbox">
                        <img src={b.img} className="zoom img-fluid " alt="" />
                      </a>
                    </div>
                  })

                }

              </div>
              {/* End Gallery */}


            </div>
          </div>
        </div>
      </section>
    </>
  )
}
