import React from 'react'
import Pdfreader from './Pdfreader'
import { Helmet } from "react-helmet-async";

const Brochure = () => {
  return (
    <>
      <Helmet prioritizeSeoTags>
        <title>Brochure- ABP Security &amp; Facility Services</title>
        <meta name="description" content="Check our brochure" />
      </Helmet>
      <section>
        <div className="container">

          <div className="other-pg-heading">
            <h2 className='h1-responsive'>Our Brochure</h2>
          </div>

          <section className="mb-4">
            <div className="col-lg-12 all-page-container">
              <Pdfreader></Pdfreader>
            </div>
          </section>
        </div>

      </section>
    </>
  )
}

export default Brochure