import React from 'react'
import { Helmet } from "react-helmet-async";

const About = () => {
  return (
    <>
      <Helmet>
        <title>Best Security Guard Agency in Mumbai | ABP Security & Facility Services</title>
        <meta name="description" content="ABP Security & Facility security agency in Mumbai is committed to providing the best Security guard services in Mumbai, Navi Mumbai & Thane with a strong team working 24X7, just for you." />
      </Helmet>
      <section className="welcome_section ">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="other-pg-heading">
                <h2 className='h1-responsive'>About Us</h2>
              </div>
              <div>&nbsp;</div>
              <p>This is reference to the above subject, on behalf of the entire <strong>ABP Security & Facility Services</strong> we wish
                to sincerely thank you for showing keen interest In our services. We take this valuable opportunity to
                introduce our organization, in all humility and earnest.</p>

              <p><strong>ABP Security &amp; Facility Services</strong> has powered by our people. A team unlike any others within the
                security &amp; facility industry. We embrace change, challenge and client expectations. We continuously
                implement new strategies and strive for an environment which creates a culture of constant
                improvement.</p>

              <p><strong>ABP Security &amp; Facility Services</strong> has a policy, which provides our client response to any service request
                in less than three hours.</p>

              <p><strong>ABP Security &amp; Facility Services</strong> our integrity as service provider helps ensure we honour all our
                commitments to the clients which have given us the honour to provide service to them. ABP Security &amp;
                Facility Services has the experience, dedication, organization, intellectual capital and fiscal strength to
                offer a complete service.</p>

              <p>To provide the highest quality, reliability and effectiveness in manpower service for our clients, while
                continually meeting or exceeding our client's expectations.</p>

              <p>Please be assured, that ABP Security &amp; Facility Services provide immense value addition to your
                organization, through the following successful tried and tested methods.</p>
                <div>&nbsp;</div>
              <h4> RECRUITMENT &amp; SECURITY TRAINING :</h4>
              <p>All Guarding Personnel deployed by ABP Security &amp; Facility Services undergo the basic Security
                Training Course which imparts the necessary skill to perform their duties with great efficiency and
                productivity. The training process is on-going and progressive throughout the employee's carrier to
                refresh and further develop their knowledge.</p>
              <p>Our site specific training course are designed after discussions with the client and assessing the training
                need of the staff. The aim of these courses is to enhance the security skills of the security personnel and
                develop management skills in the supervisory and management staff.</p>

              <div>&nbsp;</div>
              <h4>SUPERVISION :</h4>
              <p>Area managers are assigned with the task of deploying security resources, in an establishment and
                promptly and systematically resolve all queries. The area managers consistently observed these
                personnel by visiting establishment frequently by having surprise visit during any time of the day and
                night.</p>

                <div>&nbsp;</div>
              <h4>HR POLICY :</h4>
              <p><strong>ABP Security &amp; Facility Services</strong> believes personal welfare and professional development are key to
                success. We pay our employees well, on time; while offering career development programs through
                regular training, learning and development while undergoing rigorous assessment. This help achieving
                professional growth.</p>
              <p style={{textDecoration: 'underline', fontStyle:'italic', fontWeight:'500'}}>A Positive working environment delivers better services to you.</p>

              <div>&nbsp;</div>
              <h4>GOVERNMENT COMPLIANCES :</h4>
              <p>Every employee is explained his/her rights and responsibilities within the parameters of the company
                policy. We adhere to all legal compliance and we follow the minimum wages policy as directed by
                governing state Government / Guard Board.</p>
                <div>&nbsp;</div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default About