import React from 'react'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import "./Banner.css"
import banner1 from "../../assets/images/banner1.jpg"
import banner2 from "../../assets/images/banner2.jpg"
import Parser from 'html-react-parser';
import { Link } from "react-router-dom";

const Banner = () => {


    const getConfigurableProps = () => ({
        showThumbs: false,
        selectedItem: 0,
        autoPlay: true,
        infiniteLoop: true,
        useKeyboardArrows: true,
        swipeable: false,
        transitionTime: 1000,
        swipeScrollTolerance: 5,
        showStatus: false,
        interval: 3000

        // showStatus: boolean('showStatus', true, tooglesGroupId),
        // showIndicators: boolean('showIndicators', true, tooglesGroupId),

        // stopOnHover: boolean('stopOnHover', true, tooglesGroupId),
        // swipeable: boolean('swipeable', true, tooglesGroupId),
        // dynamicHeight: boolean('dynamicHeight', true, tooglesGroupId),
        // emulateTouch: boolean('emulateTouch', true, tooglesGroupId),
        // autoFocus: boolean('autoFocus', false, tooglesGroupId),
        // thumbWidth: number('thumbWidth', 100, {}, valuesGroupId),

        // interval: number('interval', 2000, {}, valuesGroupId),
        // transitionTime: number('transitionTime', 500, {}, valuesGroupId),
        // swipeScrollTolerance: number('swipeScrollTolerance', 5, {}, valuesGroupId),
        // ariaLabel: text('ariaLabel', undefined),
    });

  
    const bannerData = [{
        img:banner1,
        title:"<h2>We Are</h2><h1>Provided security services</h1>",
        desc:`<p>This is reference to the above subject, on behalf of the entire ABP Security & Facility Services we wish to sincerely thank you for showing keen interest In our services. We take this valuable opportunity....  <button className="btn btn-info btn-sm">read more</button></p>`
    },
    {
        img:banner2,
        title:"<h1>Need Security?</h1><h3>We Are Ready To Help You</3>",
        desc:`<p>The ABP SECURITY AND FACILITY SERVICES pride themselves on the quality and professionalism of their staff as they are the window of the business what they do. how they do it, when in or out of uniform services</p>`
    }];


    const htmlParserTransform = (node, index) => {
        if (node.type === "tag" && node.name==="button") { 
          return (
            <Link to="/about-us" className='link'>read more</Link>
          )
        }
    
      }
    

    return (
        <section className="slider_section">
            <Carousel  {...getConfigurableProps()}>
                
                     {
                    bannerData.map( (b,i) => {
                    return <div key={i}>
                        <div className="slider_box" style={{ backgroundImage: `url(${b.img})`}}>
                            <div className="fixed_company-detail">
                                <p>
                                    ABP Security
                                </p>
                            </div>
                            <div className='slider-detail'>
                                <div className="slider_detail-heading">
                                    {Parser(b.title)}
                                </div>
                                <div className="slider_detail-text">
                                    {Parser(b.desc,{replace:htmlParserTransform})}
                                </div>
                            </div>
                        </div>
                    </div>
                    } )
                }
            </Carousel>

        </section>
    )
}

export default Banner