import React, { useEffect, useState } from 'react'
import SimpleMap from './SimpleMap'
import ReCAPTCHA from "react-google-recaptcha";
import { useForm } from 'react-hook-form';
import { Helmet } from 'react-helmet-async';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { useNavigate } from "react-router-dom";
import constants from './AppConstant';


const Contact = () => {
  const url = window.location.href.includes('localhost') ? 'http://localhost:8081/sendmail' : '//abpsecurityservice.in/api';
  let navigate = useNavigate();
  const [isButtonDisabled, setIsButtonDisabled] = useState(true)
  const { register, getFieldState, formState: { errors}, handleSubmit, setValue } = useForm({
    mode: "onChange",
    defaultValues: {
      name: "",
      email: "",
      mobile: "",
      message: "",
      captchaToken: ""

    }
  });

  const MySwal = withReactContent(Swal)

 

  /** Recaptcha related filed and data */
  const reCaptchaKey = "6LcopIggAAAAAEeEcree9VKX98m8ZCNSdcCthGy1"
  useEffect(() => {
    register('captchaToken', { required: true });
  });

  function onChange(token) {
    setValue('captchaToken', token, { shouldValidate: true });
  }
  /** end  */

  const onSubmit = async (data) => {

    let headersList = {
      "Content-Type": "application/json",
      "Accept": "application/json"
     }

    const response = await fetch(url, { 
      method: "POST",
      body: JSON.stringify(data),
      headers: headersList
    }).catch((error) => {
      console.error('Error:', error);
    });

    const res =  await response.json();
    if(res === "success"){
     
      MySwal.fire({
        title: <p>Thank You</p>,
        html:<div class="alert alert-success" role="alert">Your record successfully submitted. We will contact you soon.</div>,
        icon:"success"
       
      }).then(() => {
        navigate("/");
      });

    }else{

      let err = '';
      for (const item of Object.entries(res)) {
          if(item && item[1]){
              const msg = Object.values(item[1])[0];
              err +=`<div class="alert alert-danger" role="alert">${msg}</div>`;
          }
      }

      MySwal.fire({
        title: <p>Error!</p>,
        html:`<div>${err}</div>`,
        icon:"error"
      })
    }
   
  
  };

  const getClassNames = (field) => {
    let cssClass = 'form-control';
    if(getFieldState(field).isTouched){
        cssClass += getFieldState(field).error ? ' is-invalid' : ' is-valid'
    }
    return cssClass;
  }

  const onCaptchLoad = () => {
    setIsButtonDisabled(true);
  }

  return (
    <>
      <Helmet prioritizeSeoTags>
        <title>Best Security Guard Company in Mumbai, Navi Mumbai & Thane</title>
        <meta name="description" content="Security guard agency in Mumbai, Navi Mumbai & Thane. Contact us now for home, residential, bank, personal, corporate, hotel or any event security services." />
      </Helmet>
      <section>
        <div className="container">

          <div className="other-pg-heading">
            <h2 className='h1-responsive'>Contact us</h2>
          </div>
          <div>&nbsp;</div>

          {/* <!--Section: Contact v.2--> */}
          <section className="mb-4">

            {/* <!--Section description--> */}
            <p className=" w-responsive mx-auto mb-5">Do you have any questions? Please do not hesitate to contact us directly. Our team will come back to you within
              a matter of hours to help you.</p>

            <div className="row">

              {/* <!--Grid column--> */}
              <div className="col-md-9 mb-md-0 mb-5">
                <form className="needs-validation" noValidate onSubmit={handleSubmit(onSubmit)}>

                  {/* <!--Grid row--> */}
                  <div className="row">

                    {/* <!--Grid column--> */}
                    <div className="col-md-6">
                      <div className="form-group">
                        <input type="text" placeholder='Your name' {...register("name", { required: true })} className={ getClassNames('name') } />
                        {errors.name && <div className="err-msg">Name is required.</div>}
                      </div>
                    </div>
                    {/* <!--Grid column--> */}

                    {/* <!--Grid column--> */}
                    <div className="col-md-6">
                      <div className="form-group">
                        <input type="text" placeholder='Your Mobile Number' {...register("mobile",{ 
                          required: "Mobile number is required.", 
                          pattern:{value:/^[0-9]*$/, message: "Only digits are allowed"},
                          maxLength:{value:10, message:'Maximum 10 number are allowed' }, 
                          minLength:{value:10, message:'please enter 10 digits mobile number'} ,
                          
                        })
                        } className={ getClassNames('mobile') } />
                        <div className="err-msg">{errors.mobile && errors.mobile?.message} </div>
                      </div>
                    </div>
                    {/* <!--Grid column--> */}

                  </div>
                  {/* <!--Grid row--> */}

                  {/* <!--Grid row--> */}
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <input type="email"
                          {...register('email',{
                            required: 'Email is required',
                            pattern: {
                              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                              message: "Please enter a valid email"
                            }

                          })}
                          className={ getClassNames('email')}
                          placeholder='Your email'
                          autoComplete="off"
                        />
                        <div className="err-msg">{errors.email && errors.email?.message} </div>
                      </div>
                    </div>
                  </div>
                  {/* <!--Grid row--> */}

                  {/* <!--Grid row--> */}
                  <div className="row">

                    {/* <!--Grid column--> */}
                    <div className="col-md-12">

                      <div className="form-group">
                        <textarea type="text" {...register('message', { required: true })} rows="2" className="form-control md-textarea" placeholder='Your Message'></textarea>
                        {errors.message && <div className="err-msg">Message is required.</div>}
                      </div>

                    </div>
                  </div>
                  {/* <!--Grid row--> */}

                  <div className='row'>
                    <div className="col-md-12">

                      <div className="form-group">
                        <ReCAPTCHA
                          sitekey={reCaptchaKey}
                          onChange={onChange}
                          onExpired={onChange}
                          asyncScriptOnLoad={ onCaptchLoad }
                        />
                        {errors.captchaToken && <div className="err-msg">ReCAPTCHA check is required.</div>}
                      </div>
                    </div>
                  </div>

                  {isButtonDisabled && <div className="text-center text-md-left">
                    <input type='submit' className="btn btn-primary" />
                  </div> }
                  <div className="status"></div>

                </form>


              </div>

              <div className="col-md-3 text-center">
                <ul className="list-unstyled mb-0">
                  <li><i className="fas fa-map-marker-alt fa-2x"></i>
                    <p>{constants.ADDRESS}</p>
                  </li>

                  <li><i className="fas fa-phone mt-2 fa-2x"></i>
                    <p>+ 91 976 815 8592</p>
                  </li>

                  <li><i className="fas fa-envelope mt-2 fa-2x"></i>
                    <p>&#097;&#098;&#112;&#115;&#099;&#101;&#114;&#116;&#064;&#103;&#109;&#097;&#105;&#108;&#046;&#099;&#111;&#109;</p>
                  </li>
                </ul>
              </div>


            </div>

          </section>
          {/* <!--Section: Contact v.2--> */}

          <section className="my-5">
            <div className="row">

              <SimpleMap></SimpleMap>
            </div>
          </section>
        </div>
      </section>
    </>
  )
}

export default Contact